<template>
    <v-container>
        <h3 class="primary--text mb-2">Project Summary</h3>
        <h4>{{ project.name }}</h4>
        <h3 style="text-decoration:underline;">Project Details</h3>
        <p><strong>Project ID:</strong> {{ project.project_code }}</p>
        <p><strong>Project Type:</strong> {{ project.type.name }}</p>
        <p><strong>Client:</strong> {{ project.client }}</p>
        <p><strong>Start Date:</strong> {{ project.start_date }}</p>
        <p><strong>End Date:</strong> {{ project.end_date }}</p>
        <p><strong>Colour:</strong> {{ project.colour }}</p>
        <p><strong>Radius (Meters):</strong> {{ project.radius }}</p>
        <p><strong>Working Hours:</strong> {{ project.working }}</p>
        <p><strong>Maximum OT Hours:</strong> {{ project.overtime }}</p>
        <p><strong>Project Description:</strong></p>
        <p>{{ project.description }}</p>
        <h3 style="text-decoration:underline;">Procurement</h3>
        <p><strong>Vendors:</strong></p>
        <div v-if="project.vendor_members.length > 0">
            <p v-for="(vendor,index) in project.vendor_members" v-bind:key="vendor.id">{{ index+1 }}. {{ vendor.name }} </p>
        </div>
        <h3 style="text-decoration:underline;">Warehouse</h3>
        <p><strong>Tools/Items Required: </strong></p>
        <div v-if="JSON.parse(project.tools).length > 0">
            <p v-for="(tool,index) in JSON.parse(project.tools)" v-bind:key="index">{{ index+1 }}. {{ tool }} </p>
        </div>
        <h3 style="text-decoration:underline;">Field Team</h3>
        <div v-if="project.fieldagentsmembers.length > 0">
            <p v-for="(member,index) in project.fieldagentsmembers" v-bind:key="member.id">{{ index+1 }}. {{ member.name }} - {{ member.email }}</p>
        </div>
        <h3 style="text-decoration:underline;">Team Members</h3>
        <div v-if="project.teammembers.length > 0">
            <p v-for="(member,index) in project.teammembers" v-bind:key="member.id">{{ index+1 }}. {{ member.name }} - {{ member.position }}</p>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name:'ProjectSummary',
    computed: {
        ... mapGetters ( {project : "getCurrentProject"})
    }

}
</script>

<style scoped>
p{
    margin-bottom: 0px;
}
</style>